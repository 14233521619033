import { GlobalHoverService } from 'o365-vue-services';
import PersonHover from 'system.libraries.vue.components.PersonHover.vue';

export default {
    mounted(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();

        let expandedInfo = false;
        let popperOptions = undefined;
        let personId = undefined;
        if (typeof binding.value == 'number') {
            personId = binding.value;
        } else {
            if (binding.value?.expandedInfo) { expandedInfo = true; }
            if (binding.value?.popperOptions) { popperOptions = binding.value?.popperOptions; }
            personId = binding.value?.personId;
        }
        service.bindElement(el, {
            component: PersonHover,
            popperOptions: popperOptions,
            props: {
                personId: personId,
                expandedInfo: expandedInfo,
            },
        })
    },
    updated(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        const props = service.getPropsFromEl(el)
        let personId = undefined;
        let popperOptions = undefined;
        if (typeof binding.value == 'number') {
            personId = binding.value;
        } else {
            personId = binding.value?.personId;
            if (binding.value?.popperOptions) { popperOptions = binding.value?.popperOptions; }
        }

        if (props && props.personId !== personId) {
            props.personId = personId;
        }
        props.popperOptions = popperOptions;
    },
    unmounted(el: HTMLElement, _binding: any) {
        const service = GlobalHoverService.getService();
        service.unbindElement(el);

    }
};